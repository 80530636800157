import { Injectable } from '@angular/core';
import { NSessionStorageService } from './n-sessionStorage.service';
import { NLocalStorageService } from './n-localStorage.service';

@Injectable()
export class NLogoutService {

  nSessionStorage = new NSessionStorageService();
  // nLocalStorageService = new NLocalStorageService();
  constructor(private nLocalStorageService: NLocalStorageService) { }

  logout() {
    this.nSessionStorage.clearSessionStorage();
    this.nLocalStorageService.clearLocalStorage();
    return true;
  }

}
