import { Injectable } from '@angular/core';
// import { MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { NSystemService } from './n-system.service';
import { NPubSubService } from './n-pubSub.service';


@Injectable()
export class NSnackbarService {
  snackBarListner;
  private snackService = new Subject<any>();
  constructor(private nPubSubService: NPubSubService) {
    if (NSystemService.getInstance().getVal('useDefaultExceptionUI')) {
      this.snackBarListner = this.nPubSubService.$sub('openSnackBar', (msg) => {
        this.openSnackBar(msg);
      })
    }
  }

  get() {
    return this.snackService.asObservable();
  }



  add(data: {
    msg: string, timeout?: number, background?: string, color?: string, customClass?: any,
    action?: { text: string, onClick?: Function, color?: string }, onAdd?: Function, onRemove?: Function
  }) {
    this.snackService.next({
      action: 'add',
      data: data
    });
  }

  remove(id: string) {
    this.snackService.next({ action: 'remove', id: id });
  }

  clear() {
    this.snackService.next({ action: 'clear' });
  }

  openSnackBar(message, timeout?, ) {
    this.add({
      msg: message,
      timeout: timeout || 5000,
      action: {
        text: 'Close',
        onClick: (snack) => {
         
        },
      },
      onAdd: (snack) => {
      },
      onRemove: (snack) => {
      }
    });
  }

}
