import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatSnackBar } from "@angular/material";
import { NAuthGuardService } from './n-authGaurd.service'
import { NDataModelService } from './n-dataModel.service';
// import { NDataSourceService } from './n-dataSource.service';
import { NHttpService } from './n-HTTP.service';
import { NHTTPLoaderService } from './n-HTTPLoader.service';
import { NLocalStorageService } from './n-localStorage.service';
import { NLoginService } from './n-login.service';
import { NLogoutService } from './n-logout.service';
import { NNotificationService } from './n-notification.service';
import { NSnackbarService } from './n-snackbar.service';
import { NSystemService } from './n-system.service';
import { NPubSubService } from './n-pubSub.service';
import { NSessionStorageService } from './n-sessionStorage.service'
import { NTokenService } from './n-token.service';
import { HttpClientModule } from '@angular/common/http';
// import { RouterModule } from '@angular/router';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
import { NgForageModule } from 'ngforage';

import { SnackComponent } from './snackbar/snack/snack.component';
import { SnackbarComponent } from './snackbar/snackbar/snackbar.component';
// import {SafeHtmlPipe} from './snackbar/pipe/safe-html.pipe';


// export function updateEnvProperties(env) {
//   console.log(env);
//   NSystemService.getInstance().environments = env;
// }

export function startupServiceFactory(startupService: NLocalStorageService) {
  const res = () => {
    // console.log('module before initstorage call');
    return startupService.initStorage();

  }
  return res;
}


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    HttpModule,
    NgForageModule.forRoot()
    // BrowserModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NHttpService,
      multi: true
    },
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [NLocalStorageService],
      multi: true
    },
    NAuthGuardService,
    NDataModelService,
    // NDataSourceService,
    NHttpService,
    NHTTPLoaderService,
    NLocalStorageService,
    NLoginService,
    NLogoutService,
    NNotificationService,
    NSnackbarService,
    NPubSubService,
    //     NSystemService,
    NSessionStorageService,
    NTokenService
  ],
  declarations: [
    SnackComponent,
    SnackbarComponent
  ],
  exports: [
    SnackComponent,
    SnackbarComponent
  ]

})
export class NeutrinosSeedServicesModule {

}
