// import { environment } from '../../environments/environment';
import { NUtility } from './n-util.service';
import { NLocalStorageService } from './n-localStorage.service';
import { Injectable } from '@angular/core';
import * as platform from 'platform';

// @dynamic
export class NSystemService {
  private static instance: NSystemService;

  private _deviceType: string;
  // private _deviceUUID;
  uuid;
  properties;
  static envProperties;
  static getInstance() {
    if (!this.instance) {
      this.instance = new NSystemService();
    }
    return this.instance;
  }

  private constructor() {
    this._deviceType = this.checkDevice();
  }

  /**
   * Seter and getter for the environments
   */

  // set environments(properties) {
  //   console.log(properties);
  //   NSystemService.envProperties = properties;
  //   this.properties = this.getVal('properties');
  // }

  // get environments() {
  //   return NSystemService.envProperties;
  // }

  /**
   * Returna 'mobile' or 'browser' based on the platform
   */
  checkDevice(): string {
    if (window['device'] && (window['device']['platform'] != 'browser' || window['device']['platform'] != 'Browser')) {
      return 'mobile';
    } else if (window['device'] && (window['device']['platform'] == 'browser' || window['device']['platform'] == 'Browser')) {
      return 'cordova_browser';
    } else {
      return 'browser';
    }
  }


  public get deviceType() {
    return this._deviceType;
  }



  public getVal(key) {
    if (!NSystemService.envProperties) {
      NSystemService.envProperties = window['neutrinos']['environments'];
      this.properties = NSystemService.envProperties.properties;
    }

    if (key == 'properties') {
      if (NSystemService.envProperties) {
        return NSystemService.envProperties.properties;
      } else {
        return undefined;
      }
    } else {
      if (NSystemService.envProperties && NSystemService.envProperties.properties) {
        return NSystemService.envProperties.properties[key];
      } else {
        return undefined;
      }

    }

  }

  public isAndroid() {
    if (window['device'] && window['device']['platform'] == 'Android') {
      return true;
    } else {
      return false;
    }
  }

  public isIOS() {
    if (window['device'] && window['device']['platform'] == 'iOS') {
      return true;
    } else {
      return false;
    }
  }

  public getAndroidVersion() {
    if (this.isAndroid()) {
      return window['device']['version'];
    } else {
      return '';
    }
  }

  public getAuthUrl() {
    // this.properties = this.getVal('properties');
    return this.properties.baseUrl + this.properties.tenantName + '/auth/';
  }
  public getTenantUrl() {
    return this.properties.baseUrl + this.properties.tenantName + '/';
  }

  public getAppUrl() {
    return this.properties.baseUrl + this.properties.tenantName + '/proxy/' + this.properties.appName + '/';
  }

  public getProxyUrl() {
    return this.properties.baseUrl + this.properties.tenantName + '/proxy/';
  }

  public getDataModelUrl() {
    return this.properties.baseUrl + this.properties.tenantName + '/datamodel/' + this.properties.appDataSource + '/' + this.properties.appName + '/';
  }

  public getFileIOUrl() {
    return this.properties.baseUrl + this.properties.tenantName + '/fileio/' +
      this.properties.appDataSource + '/' + this.properties.appName + '/';
  }

  public getPlatformDetails(type) {
    const pDetails = {
      platform: platform.os.family,
      platformVersion: platform.os.version,
    };
    if (type === 'browser') {
      return Object.assign(pDetails, {
        type: 'browser',
        browser: platform.name,
        browserVersion: platform.version,
      });
    } else if (type === 'mobile' || type === 'cordova_browser') {
      return Object.assign(pDetails, {
        type: 'mobile',
        model: window['device'].model,
        manufacturer: window['device'].manufacturer,
        isVirtual: window['device'].isVirtual
      });
    }
  }
}
