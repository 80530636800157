import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NSystemService } from './n-system.service';
import { NTokenService } from './n-token.service';
import { NPubSubService } from './n-pubSub.service';
import { NSessionStorageService } from './n-sessionStorage.service';
import { NLocalStorageService } from './n-localStorage.service';
import { NNotificationService } from './n-notification.service';
import 'rxjs/add/operator/map';
import { Observable } from "rxjs/Observable";


@Injectable()
export class NLoginService {
  loginUrl;
  appProperties;
  systemService;
  nSessionStorage;
  uuid;
  details: any;

  @Output() loginCompleted = new EventEmitter();
  constructor(private http: HttpClient, private pubSubService: NPubSubService, private notificationService: NNotificationService,
    private nLocalStorageService: NLocalStorageService, private nTokenService: NTokenService) {
    this.systemService = NSystemService.getInstance();
    // this.nTokenService = new NTokenService();
    this.nSessionStorage = new NSessionStorageService();
    // this.nLocalStorageService = new NLocalStorageService();
  }


  login(userName, password, isRemember?) {
    this.appProperties = this.systemService.getVal('properties');
    this.loginUrl = this.systemService.getAuthUrl() + this.appProperties.appName;
    this.uuid = this.nLocalStorageService.getValue('uuid');
    if (!this.uuid) {
      this.uuid = this.nLocalStorageService.checkDeviceId();
    }
    this.details = {
      username: userName,
      password: password,
    };
    this.details.platformDetails = this.systemService.getPlatformDetails(this.systemService.checkDevice());
    this.details.platformDetails['uuid'] = this.uuid;
    return this.http.post(this.loginUrl, JSON.stringify(this.details)).map(result => {
      const tokensObj = result;
      if (tokensObj) {
        this.nTokenService.updateTokens(tokensObj, isRemember);
      }
      if (this.systemService.getVal('firebaseSenderId') != 'FIREBASE_SENDER_ID' && this.systemService.getVal('firebaseAuthKey') != 'FIREBASE_AUTH_KEY') {
        // this.pubSubService.$pub('firebaseRegister');
      }
      this.pubSubService.$pub('loginComplete');
      return (result);
    }, error => {
      return (error);
    });
  }

  isLoggedIn() {
    return this.nLocalStorageService.initStorage().then(result => {
      if (this.nSessionStorage.getValue('accessToken') && this.nSessionStorage.getValue('refreshToken') &&
        this.nSessionStorage.getValue('accessToken') != 'null' && this.nSessionStorage.getValue('refreshToken') != 'null') {
        return true;
      }
      return false;
    }).catch(error => {
      return false;
    });

  }
}
