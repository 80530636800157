import { Component, Input } from '@angular/core';

@Component({
  selector: 'n-snack',
  template: `
     <div class="snack" [ngStyle]="{background: background ? background : '#323232' , color: color ? color : '#fff'}" [ngClass]=" ['snackbar-layout', 'customClass' ]">
          <ng-content></ng-content>
       </div> 
  `,
  styles: [`.snack-action{cursor:pointer;color:#2196f3;font-weight:700}.container{display:flex;flex-direction:row;flex-wrap:wrap}.child{width:60%;flex:1 0;margin-right:.5em}.snackbars.bottom-center{align-items:middle;left:50%;-webkit-transform:translate(-50%,0);transform:translate(-50%,0);bottom:1px;align-items:center}.snackbars{position:fixed;z-index:99999;max-width:100%;word-wrap:break-word;display:inline;font-family:Roboto;letter-spacing:1px}.snack{padding:1em;border-radius:.3em;max-height:50vh;overflow:auto}`]
})
export class SnackComponent {
  @Input() background;
  @Input() color;
  @Input() customClass;
}
