import { NSystemService } from './n-system.service';
import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { NgForage, NgForageCache, NgForageConfig, CachedItem } from 'ngforage';
import { NUtility } from './n-util.service';

@Injectable()
export class NLocalStorageService {

  storageCache: any = {};
  private _deviceUUID;
  private nativeStorageI;
  constructor(private ngfConfig?: NgForageConfig, private readonly ngf?: NgForage, private readonly ngfCache?: NgForageCache) {
  }



  initStorage() {
    return new Promise((resolve, reject) => {
      if (window['cordova']) {
        this.initNgForage();
      }
      this.ngf.iterate((value, key, iteratonNumber) => {
        this.storageCache[key] = value;
      }).then(result => {
        this.checkDeviceId();
        return resolve('iteration is completed')
      }).catch(error => {
        return reject(error);
      })
    })
  }

  getStorage() {
    return this.storageCache;
  }


  setValue(key, value) {
    if (window['cordova']) {
      this.initNgForage();
    }
    this.storageCache[key] = value;
    return this.ngf.setItem(key, value).then(result => {
      return result;
    }, error => {
      console.log(error);
    })
  }

  getValue(key): any | Promise<any> {
    if (!this.storageCache[key]) {
      return null;
    } try {
      const obj = this.storageCache[key]
      return JSON.parse(obj);
    } catch (error) {
      return this.storageCache[key];
    }
  }

  remove(key) {
    delete this.storageCache[key];
    if (window['cordova']) {
      this.initNgForage();
    }
    this.ngf.removeItem(key).then(fulfilled => {
      delete this.ngf[key];
    }).catch(error => {
      console.error('Could not remove', key);
    })
  }

  clear() {
    this.storageCache = {};
    this.ngf.clear();
  }

  private pluginCheck() {
    if (window['cordova'] && window['NativeStorage']) {
      this.nativeStorageI = window['NativeStorage'];
      // return true;
    }
    // this.initStorage();
  }

  private getItemNs(key) {
    return new Promise((resolve, reject) => {
      if (window['cordova'] && window['NativeStorage']) {
        this.nativeStorageI.getItem(key, result => {
          resolve(result);
        }, error => {
          reject(error);
        })
      }
    })
  }

  private setItemNs(key, value) {
    return new Promise((resolve, reject) => {
      if (window['cordova'] && window['NativeStorage']) {
        this.nativeStorageI.setItem(key, value, result => {
          resolve(result);
        }, error => {
          reject(error);
        })
      }
    })
  }

  private removeItemNs(key) {
    return new Promise((resolve, reject) => {
      if (window['cordova'] && window['NativeStorage']) {
        this.nativeStorageI.remove(key, (result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
      }
    })
  }

  private clearNs() {
    return new Promise((resolve, reject) => {
      if (window['cordova'] && window['NativeStorage']) {
        this.nativeStorageI.clear(result => {
          resolve(result);
        }, error => {
          reject(error);
        })
      }
    })
  }

  private initNgForage() {
    this.ngfConfig.configure({
      name: 'MyApp',
      driver: [
        NgForageConfig.DRIVER_LOCALSTORAGE,
      ]
    });

  }

  private promiseReflect(promise) {
    return promise.then(resolved => { return { v: resolved, status: 'resolved' } }, error => { return { e: error, status: 'rejected' } })
  }

  clearLocalStorage() {
    this.remove('userObj');
    this.remove('accessToken');
    this.remove('refreshToken');
    this.remove('registrationId');
  }

  /**
   * Due to timing issues and circular dependency checkDeviceId is moved from NSystemService
  */

  checkDeviceId() {
    if (NSystemService.getInstance().checkDevice() === 'browser') {
      this._deviceUUID = this.getValue('uuid');

      if (!this._deviceUUID) {
        this._deviceUUID = new NUtility().generateUUID();
        this.setValue('uuid', this._deviceUUID);
      }
    } else {
      window['plugins'].uniqueDeviceID.get((uuid) => {
        this._deviceUUID = uuid;
        this.setValue('uuid', this._deviceUUID);
      });
    }
    return this._deviceUUID;
  }

  public get deviceUUID() {
    return this._deviceUUID;
  }
}
