import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
// import { NSnackbarService } from './n-snackbar.service';
import { NLogoutService } from './n-logout.service';
import { Observable } from "rxjs/Observable";
import { NSystemService } from './n-system.service';
import { NPubSubService } from './n-pubSub.service';
// import { NSnackbarService } from './snackbar/service/snackbar.service';

@Injectable()
export class NHTTPLoaderService {
  private _isHTTPRequestInProgress = new Subject<boolean>();
  _isHTTPRequestInProgress$ = this._isHTTPRequestInProgress.asObservable();
  constructor(private nLogoutService: NLogoutService, private nPubSubService: NPubSubService) {
  }

  isHTTPRequestInProgress(bool) {
    this._isHTTPRequestInProgress.next(bool);
  }

  alertError(error) {
    if (error.status < 200 || error.status > 500) {
      if (error.status === 0) {
        this.nPubSubService.$pub('openSnackBar', 'Connectivity issue');
      } else {
        this.nPubSubService.$pub('openSnackBar', 'Response failure')
      }
    } else if (error.status === 401) {
      this.nLogoutService.logout();
      if (error.error) {
        this.nPubSubService.$pub('openSnackBar', error.error)
      } else {
        this.nPubSubService.$pub('openSnackBar', error)
      }
    } else if (error.error) {
      if (error.error instanceof Object) {
        this.nPubSubService.$pub('openSnackBar', error.error.message)
      } else {
        this.nPubSubService.$pub('openSnackBar', error.error)
      }
    } else {
      this.nPubSubService.$pub('openSnackBar', 'Response failure')
    }
  }

}


