import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { NLoginService } from './n-login.service';

@Injectable()
export class NAuthGuardService implements CanActivate {

  constructor(private nLoginService: NLoginService, private router: Router) { }

  canActivate() {
    return this.nLoginService.isLoggedIn().then(result => {
      if (result) {
        return true;
      } else {
        this.router.navigate(['unauthorized']);
        return false;
      }
    })

  }

}
